<template>
  <div class="project-info">
    <ProjectMenu />
    <div class="wrap">
      <el-descriptions title="项目基本信息" border :column="2">
        <template slot="extra">
          <el-button
            type="primary"
            v-if="isRead"
            @click="toggleEditMode"
            size="small"
            plain
            icon="el-icon-edit"
            >编辑</el-button
          >
          <el-button
            type="primary"
            v-if="!isRead"
            size="small"
            plain
            icon="el-icon-check"
            @click="saveEditData"
            >保存</el-button
          >
          <el-button
            type="danger"
            v-if="!isRead && action != 'add'"
            @click="toggleReadMode"
            size="small"
            plain
            icon="el-icon-close"
            >取消</el-button
          >
          <el-button
            type="danger"
            v-if="isRead || action =='add'"
            @click="$router.go(-1)"
            size="small"
            plain
            icon="el-icon-back"
            >返回</el-button
          >
        </template>
        <el-descriptions-item label="项目名称" labelClassName="my-label" contentClassName="my-content">
          <span v-if="isRead">{{ readData.proName }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.proName"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="项目编号" labelClassName="my-label" contentClassName="my-content">
          <span v-if="isRead">{{ readData.projectSysNo }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.projectSysNo"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="项目简介">
          <span v-if="isRead">{{ readData.simName }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.simName"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="项目状态">
          <span v-if="isRead && readData.proStatus == '1'">带开工</span>
          <span v-if="isRead && readData.proStatus == '2'">进行中</span>
          <span v-if="isRead && readData.proStatus == '3'">已完结</span>
          <el-select
            v-if="!isRead"
            size="small"
            v-model="editData.proStatus"
            placeholder="请选择项目状态"
            style="width: 100%;"
          >
            <el-option
              v-for="item in projectStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item label="项目负责人">
          <span v-if="isRead">{{ readData.proMaster }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.proMaster"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="负责人电话">
          <span v-if="isRead">{{ readData.masterMobile }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.masterMobile"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="项目经理">
          <span v-if="isRead">{{ readData.proManager }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.proManager"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="经理电话">
          <span v-if="isRead">{{ readData.proManMobile }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.proManMobile"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="所属地区">
          <span v-if="isRead">{{ readData.arearId }}</span>
          <el-cascader
            v-if="!isRead"
            size="small"
            style="width: 100%;"
            v-model="editData.arearIdData"
            :options="regionData"
            :props="{
              expandTrigger: 'click',
              checkStrictly: true,
              value: 'name',
              label: 'name',
            }"
            clearable
            @change="changeArearId"
          >
          </el-cascader>
        </el-descriptions-item>
        <el-descriptions-item label="项目地址">
          <span v-if="isRead">{{ readData.proAddress }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.proAddress"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="工期">
          <span v-if="isRead">{{ readData.projectDuration }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.projectDuration"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="结构形式">
          <span v-if="isRead">{{ readData.structType }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.structType"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="占地面积">
          <span v-if="isRead">{{ readData.areaCovered }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.areaCovered"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="楼栋数">
          <span v-if="isRead">{{ readData.builTotal }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.builTotal"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="地上建筑面积(㎡)">
          <span v-if="isRead">{{ readData.aboveAreaCovered }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.aboveAreaCovered"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="地下建筑面积(㎡)">
          <span v-if="isRead">{{ readData.unaboveAreaCovered }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.unaboveAreaCovered"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="建设单位">
          <span v-if="isRead">{{ readData.devoId }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.devoId"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="设计单位">
          <span v-if="isRead">{{ readData.designName }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.designName"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="监理单位">
          <span v-if="isRead">{{ readData.supervisorName }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.supervisorName"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="施工单位">
          <span v-if="isRead">{{ readData.construtId }}</span>
          <el-input
            v-if="!isRead"
            size="small"
            v-model="editData.construtId"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="备注">
          <span v-if="isRead">{{ readData.remark }}</span>
          <el-input
            v-if="!isRead"
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 10}"
            size="small"
            v-model="editData.remark"
            clearable
          ></el-input>
        </el-descriptions-item>
      </el-descriptions>
      
      <el-descriptions title="项目关键节点" border style="margin-top: 26px;">
        <template slot="extra">
          <el-button
            type="primary"
            v-if="!isRead"
            size="small"
            plain
            icon="el-icon-plus"
            @click="addPoint"
            >添加节点</el-button
          >
        </template>
      </el-descriptions>

      <el-descriptions title="" border :column="2" v-for="item, index in prProgressPointListEdit" :key="index">
          <el-descriptions-item label="节点名称" labelClassName="my-label" contentClassName="my-content">
            <span v-if="isRead">{{ item.scheduleName }}</span>
            <el-input
              v-if="!isRead"
              size="small"
              v-model="item.scheduleName"
              clearable
            ></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="节点日期" labelClassName="my-label" contentClassName="my-content">
            <span v-if="isRead">{{ item.scheduleDate }}</span>
            <el-date-picker
              v-if="!isRead"
              v-model="item.scheduleDate"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              align="right"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
            <i class="el-icon-delete delPoint" v-if="!isRead && index!= 0 && index!=prProgressPointListEdit.length-1" @click="delPoint(index)"></i>
          </el-descriptions-item>
      </el-descriptions>

      <el-descriptions title="项目附件" border :column="2" style="margin-top: 26px;">
        <el-descriptions-item label="项目效果图" labelClassName="my-label" contentClassName="my-content">
          <el-image
            v-if="isRead"
            style="width: 288px; height: 162px;"
            :src="'api' + readData.proEffectUrl"
            fit="scale-down">
          </el-image>
          <el-upload
            v-if="!isRead"
            class="upload-demo"
            drag
            action="/"
            :show-file-list="false"
            :auto-upload="true"
            :http-request='uploadProEffectUrl'
            >
            <img v-if="blob1" :src="blob1">
            <i v-if="!blob1" class="el-icon-upload"></i>
            <div v-if="!blob1" class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div v-if="!blob1" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
          </el-upload>
        </el-descriptions-item>
        <el-descriptions-item label="项目规划图" labelClassName="my-label" contentClassName="my-content">
          <el-image
            v-if="isRead"
            style="width: 288px; height: 162px;"
            :src="'api' + readData.proPlanUrl"
            fit="scale-down">
          </el-image>
          <el-upload
            v-if="!isRead"
            class="upload-demo"
            drag
            action="/"
            :show-file-list="false"
            :auto-upload="true"
            :http-request='uploadProPlanUrl'
            >
            <img v-if="blob2" :src="blob2">
            <i v-if="!blob2" class="el-icon-upload"></i>
            <div v-if="!blob2" class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div v-if="!blob2" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
          </el-upload>
        </el-descriptions-item>
        <el-descriptions-item label="项目实景图">
          <el-image
            v-if="isRead"
            style="width: 288px; height: 162px;"
            :src="'api' + readData.proRealUrl"
            fit="scale-down">
          </el-image>
          <el-upload
            v-if="!isRead"
            class="upload-demo"
            drag
            action="/"
            :show-file-list="false"
            :auto-upload="true"
            :http-request='uploadProRealUrl'
            >
            <img v-if="blob3" :src="blob3">
            <i v-if="!blob3" class="el-icon-upload"></i>
            <div v-if="!blob3" class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div v-if="!blob3" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
          </el-upload>
        </el-descriptions-item>
        <el-descriptions-item label="项目视频">
          <video 
            v-if="isRead"
            style="width: 288px; height: 162px;"
            :src="'api' + readData.proVideoUrl" controls="controls">
            您的浏览器不支持 video 标签。
          </video>
          <el-upload
            v-if="!isRead"
            class="upload-demo"
            drag
            action="/"
            :show-file-list="false"
            :auto-upload="true"
            :http-request='uploadProVideoUrl'
            >
            <!-- <img v-if="blob4" :src="blob4"> -->
            <video v-if="blob4" :src="blob4" controls="controls">
              您的浏览器不支持 video 标签。
            </video>
            <i v-if="!blob4" class="el-icon-upload"></i>
            <div v-if="!blob4" class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div v-if="!blob4" class="el-upload__tip">只能上传mp4文件，且不超过20M</div>
          </el-upload>
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<style lang="less" scope>
.project-info { 
  .my-label {
    width: 20%;
  }
  .my-content {
    width: 30%;
    position: relative;
  }
  .el-date-editor {
    width: 100% !important;
  }
  .upload-demo {
    width: 288px;
    height: 162px;
  }
  .el-upload-dragger {
    width: 288px;
    height: 162px;
  }
  .delPoint {
    position: absolute;
    right: -18px;
    top: 23px;
  }
}
</style>


<script>
import ProjectMenu from "@/components/ProjectMenu.vue";
import DeviceMenu from '@/components/DeviceMenu.vue';

export default {
  name: "ProjectInfo",
  components: {
    ProjectMenu,
  },
  data() {
    return {
      loading: null,
      regionData: [],
      projectStatus: [
        {
          value: '1',
          label: "待开工",
        },
        {
          value: '2',
          label: "进行中",
        },
        {
          value: '3',
          label: "已完结",
        },
      ],

      isRead: true,


      headers: {
        Authorization: 'Bearer ' + localStorage.token,
        // 'Content-Type': 'image/png'
      },
      fileFormData: null, // 将要上传的formdata数据
      blob1: null,
      blob2: null,
      blob3: null,
      blob4: null,

      readData: {
        id: null,
        proName: null,
        projectSysNo: null,
        simName: null,
        modules: null,
        devoId: null,
        construtId: null,
        designName: null,
        supervisorName: null,
        proMaster: null,
        masterMobile: null,
        proManager: null,
        proManMobile: null,
        arearId: null,
        proAddress: null,
        proStatus: null,
        structType: null,
        projectDuration: null,
        areaCovered: null,
        builTotal: null,
        aboveAreaCovered: null,
        unaboveAreaCovered: null,
        proStartTime: null,
        proEndTime: null,
        baseStartTime: null,
        mainStartTime: null,
        saleTime: null,
        renovatTime: null,
        checkAccpetTime: null,
        proEffectUrl: null,
        proPlanUrl: null,
        proRealUrl: null,
        proVideoUrl: null,
        remark: null,
        delFlag: null,
        createTime: null,
        createBy: null,
        updateTime: null,
        updateBy: null,
      },
      editData: {
        id: null,
        proName: null,
        projectSysNo: null,
        simName: null,
        modules: null,
        devoId: null,
        construtId: null,
        designName: null,
        supervisorName: null,
        proMaster: null,
        masterMobile: null,
        proManager: null,
        proManMobile: null,
        arearId: null,
        proAddress: null,
        proStatus: null,
        structType: null,
        projectDuration: null,
        areaCovered: null,
        builTotal: null,
        aboveAreaCovered: null,
        unaboveAreaCovered: null,
        proStartTime: null,
        proEndTime: null,
        baseStartTime: null,
        mainStartTime: null,
        saleTime: null,
        renovatTime: null,
        checkAccpetTime: null,
        proEffectUrl: null,
        proPlanUrl: null,
        proRealUrl: null,
        proVideoUrl: null,
        remark: null,
        delFlag: null,
        createTime: null,
        createBy: null,
        updateTime: null,
        updateBy: null,
      },
      prProgressPointListRead: [],
      prProgressPointListEdit: [],
      prProgressPointListInit:[
        {
          id: null,
          scheduleName: '项目开始',
          scheduleDate: '2021-12-01',
        },
        {
          id: null,
          scheduleName: '竣工验收',
          scheduleDate: '2021-12-01',
        },
      ]
    };
  },
  computed: {
    action: function () {
      return this.$route.query.action;
    },
    id: function () {
      return this.$route.query.id;
    },
  },
  mounted() {
    this.getRegionData(); // 加载省市区数据
    this.getProjectInfo(); // 根据 id 获取项目信息
  },
  watch: {},
  methods: {
    // 加载省市区数据
    getRegionData: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "get",
          url: "/data/region.json",
        })
        .then((res) => {
          console.log("getRegionData =>", res);
          that.regionData = res.data;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 根据 id 获取项目信息
    getProjectInfo: function () {
      var that = this;
      if (that.id == null & that.action == 'add') {
        that.isRead = false
        return;
      }
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        id: that.id,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/project/getProject",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log("getProjectInfo =>", res.data.prProgressPointList);
          that.readData = JSON.parse(JSON.stringify(res.data.project))
          if (!res.data.prProgressPointList.length) {
            that.prProgressPointListRead = JSON.parse(JSON.stringify(that.prProgressPointListInit))
            that.prProgressPointListEdit = JSON.parse(JSON.stringify(that.prProgressPointListInit))
          } else {
            that.prProgressPointListRead = JSON.parse(JSON.stringify(res.data.prProgressPointList))
            that.prProgressPointListEdit = JSON.parse(JSON.stringify(res.data.prProgressPointList))
          }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 进入编辑模式
    toggleEditMode: function () {
      this.isRead = false
      this.editData = JSON.parse(JSON.stringify(this.readData))
      this.editData.arearIdData = this.editData.arearId.split(' | ')
      this.blob1 = null
      this.blob2 = null
      this.blob3 = null
      this.prProgressPointListEdit = JSON.parse(JSON.stringify(this.prProgressPointListRead))
    },
    // 退出编辑模式
    toggleReadMode: function () {
      this.isRead = true
      this.prProgressPointListEdit = JSON.parse(JSON.stringify(this.prProgressPointListRead))
    },
    // 保存表单数据
    saveEditData: function () {
      var that = this
      var data = {
        id: that.id,
        projectSysNo: that.editData.projectSysNo,
        proName: that.editData.proName,
        simName: that.editData.simName,
        proMaster: that.editData.proMaster,
        masterMobile: that.editData.masterMobile,
        proManager: that.editData.proManager,
        proManMobile: that.editData.proManMobile,
        proAddress: that.editData.proAddress,
        arearId: that.editData.arearId,
        projectStatus: that.editData.projectStatus,
        projectDuration: that.editData.projectDuration,
        structType: that.editData.structType,
        builTotal: that.editData.builTotal,
        areaCovered: that.editData.areaCovered,
        aboveAreaCovered: that.editData.aboveAreaCovered,
        unaboveAreaCovered: that.editData.unaboveAreaCovered,
        proStartTime: that.editData.proStartTime,
        proEndTime: that.editData.proEndTime,
        baseStartTime: that.editData.baseStartTime,
        mainStartTime: that.editData.mainStartTime,
        saleTime: that.editData.saleTime,
        renovatTime: that.editData.renovatTime,
        checkAccpetTime: that.editData.checkAccpetTime,
        devoId: that.editData.devoId,
        construtId: that.editData.construtId,
        designName: that.editData.designName,
        supervisorName: that.editData.supervisorName,
        proEffectUrl: that.editData.proEffectUrl,
        proPlanUrl: that.editData.proPlanUrl,
        proRealUrl: that.editData.proRealUrl,
        proVideoUrl: that.editData.proVideoUrl,
        remark: that.editData.remark,
        prProgressPointsList: that.prProgressPointListEdit
      }
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/project/insertOrEditProject",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log("saveEditData =>", res);
          if (res.code == 0) {
            that.$notify({
              title: "操作成功",
              message: "操作已成功。",
              type: "success",
            });
          } else {
            that.$notify({
              title: "警告",
              message: res.data.msg,
              type: "warning",
            });
          }
          that.$router.push({ name: 'ProjectList' })
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 将所选文件添加到文件对象中
    addFileToFormData(file) {
      console.log('file =>', file);
      console.log('URL.createObjectURL(file) =>', URL.createObjectURL(file.file));
      this.imageUrl1 = URL.createObjectURL(file.file)
      this.fileFormData = new FormData(); 
      this.fileFormData.append('file', file.file);
      console.log('addFileToFormData =>', this.fileFormData.getAll('file'));
      // this.upload()
    },
    // 上传项目效果图	
    uploadProEffectUrl: function (file) {
      var that = this
      that.fileFormData = new FormData(); 
      that.fileFormData.append('file', file.file);
      var formData = that.fileFormData
      that
        .$axios({
          method: "post",
          url: "api/admin/sys-file/upload",
          data: formData,
          headers: { 
            'Authorization': 'Bearer ' + localStorage.token,
            'Content-Type': 'multipart/form-data',
          }
        })
        .then((res) => {
          console.log('upload =>', res);
          that.blob1 = URL.createObjectURL(file.file)
          that.editData.proEffectUrl = res.data.data.url
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 上传项目规划图
    uploadProPlanUrl: function (file) {
      var that = this
      that.fileFormData = new FormData(); 
      that.fileFormData.append('file', file.file);
      var formData = that.fileFormData
      that
        .$axios({
          method: "post",
          url: "api/admin/sys-file/upload",
          data: formData,
          headers: { 
            'Authorization': 'Bearer ' + localStorage.token,
            'Content-Type': 'multipart/form-data',
          }
        })
        .then((res) => {
          console.log('upload =>', res);
          that.blob2 = URL.createObjectURL(file.file)
          that.editData.proPlanUrl = res.data.data.url
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 上传项目实景图	
    uploadProRealUrl: function (file) {
      var that = this
      that.fileFormData = new FormData(); 
      that.fileFormData.append('file', file.file);
      var formData = that.fileFormData
      that
        .$axios({
          method: "post",
          url: "api/admin/sys-file/upload",
          data: formData,
          headers: { 
            'Authorization': 'Bearer ' + localStorage.token,
            'Content-Type': 'multipart/form-data',
          }
        })
        .then((res) => {
          console.log('upload =>', res);
          that.blob3 = URL.createObjectURL(file.file)
          that.editData.proRealUrl = res.data.data.url
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 上传项目视频
    uploadProVideoUrl: function (file) {
      console.log('uploadProVideoUrl');
      var that = this
      that.fileFormData = new FormData(); 
      that.fileFormData.append('file', file.file);
      var formData = that.fileFormData
      that
        .$axios({
          method: "post",
          url: "api/admin/sys-file/upload",
          data: formData,
          headers: { 
            'Authorization': 'Bearer ' + localStorage.token,
            'Content-Type': 'multipart/form-data',
          }
        })
        .then((res) => {
          console.log('upload =>', res);
          that.blob4 = URL.createObjectURL(file.file)
          that.editData.proVideoUrl = res.data.data.url
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 所属地区发生变化
    changeArearId: function (event) {
      console.log('changeArearId =>', event);
      this.editData.arearId = event.join(' | ')
      this.editData.proAddress = event.join('')
    },
    // 添加节点
    addPoint: function () {
      console.log('addPoint =>')
      var index = this.prProgressPointListEdit.length - 1
      this.prProgressPointListEdit.splice(index, 0, {
        id: null,
        scheduleName: null,
        scheduleDate: null
      })
    },
    // 删除节点
    delPoint: function (index) {
      this.prProgressPointListEdit.splice(index, 1)
    }
  },
};
</script>